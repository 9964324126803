import { HStack, chakra } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useNavbarHeight } from "../../hooks/useNavbarHeight";
import Logo from "./Logo";

const scrollTolerance = 50;

const Navbar = () => {
    const [scrolled, setScrolled] = useState(window.scrollY > 100);
    const navbarHeight = useNavbarHeight();

    // used in memo to keep navbar height in sync with blur effect
    const blurNavbarProps = useMemo(
        () => ({
            content: '""',
            w: "full",
            h: navbarHeight,
            bgColor: "rgba(0, 0, 0, 0.5)",
            pos: "absolute",
            top: 0,
            left: 0,
            backdropFilter: "blur(3px)",
            zIndex: -1,
            transition: "all .2s ease-in-out",
            opacity: scrolled ? 1 : 0,
        }),
        [navbarHeight, scrolled]
    );

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > scrollTolerance);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [setScrolled]);

    return (
        <chakra.nav
            pos="fixed"
            top={0}
            left={0}
            w="full"
            zIndex={500}
            id="navbar"
            _before={blurNavbarProps}
            h="fit-content"
        >
            <HStack px={{ base: 5, md: 10 }} h="3.5rem">
                <Logo />
            </HStack>
        </chakra.nav>
    );
};

export default Navbar;
