import {
    NewEventContext,
    NewEventContextProps,
} from "@contexts/NewEventContext";
import { useApplicationLayoutTitle } from "@hooks/useApplicationLayoutTitle";
import * as idb from "idb-keyval";
import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

const NewEventWrapper = () => {
    const [initialValuesSet, setInitialValuesSet] = useState(false);
    const [data, setData] = useState<NewEventContextProps["values"]["data"]>({
        name: "",
        startDateTime: "",
        endDateTime: "",
        minManAge: 18,
        minWomenAge: 18,
        description: "",
        address: "",
        tags: [],
        whatsAppEnabled: false,
    });
    const [onlineSale, setOnlineSale] = useState<
        NewEventContextProps["values"]["onlineSale"]
    >({
        hasOnlineSale: true,
    });
    const [paymentMethods, setPaymentMethods] = useState<
        NewEventContextProps["values"]["paymentMethods"]
    >([]);
    const [flyers, setFlyers] = useState<
        NewEventContextProps["values"]["flyers"]
    >([]);
    const [products, setProducts] = useState<
        NewEventContextProps["values"]["products"]
    >([]);

    useEffect(() => {
        const setInitialValues = async () => {
            const data = await idb.get("newEventData");
            const onlineSale = await idb.get("newEventOnlineSale");
            const paymentMethods =
                (await idb.get("newEventPaymentMethods")) ?? [];
            const flyers = (await idb.get("newEventFlyers")) ?? [];
            const products = (await idb.get("newEventProducts")) ?? [];

            if (data) setData(data);
            if (onlineSale) setOnlineSale(onlineSale ? onlineSale : {});
            if (paymentMethods) setPaymentMethods(paymentMethods);
            if (flyers) setFlyers(flyers);
            setProducts(products);
            setInitialValuesSet(true);
        };
        setInitialValues();
    }, [setData, setOnlineSale, setFlyers, setProducts, setInitialValuesSet]);

    useEffect(() => {
        const updateStoredValues = async () => {
            await idb.set("newEventData", data);
            await idb.set("newEventOnlineSale", onlineSale);
            await idb.set("newEventPaymentMethods", paymentMethods);
            await idb.set("newEventFlyers", flyers);
            await idb.set("newEventProducts", products);
        };
        if (initialValuesSet) updateStoredValues();
    }, [data, onlineSale, flyers, products, initialValuesSet, paymentMethods]);

    const resetValues = useCallback(async () => {
        setData({
            name: "",
            startDateTime: "",
            endDateTime: "",
            minManAge: 0,
            minWomenAge: 0,
            description: "",
            address: "",
            tags: [],
            whatsAppEnabled: false,
        });
        setOnlineSale({
            hasOnlineSale: true,
        });
        setPaymentMethods([]);
        setFlyers([]);
        setProducts([]);
        await idb.clear();
    }, [
        idb,
        setData,
        setOnlineSale,
        setFlyers,
        setProducts,
        setPaymentMethods,
    ]);

    useApplicationLayoutTitle("Nuevo evento");

    if (!initialValuesSet) return null;

    return (
        <NewEventContext.Provider
            value={{
                values: {
                    data,
                    onlineSale,
                    paymentMethods,
                    flyers,
                    products,
                },
                setters: {
                    setData,
                    setOnlineSale,
                    setPaymentMethods,
                    setFlyers,
                    setProducts,
                },
                resetValues,
            }}
        >
            <Outlet />
        </NewEventContext.Provider>
    );
};

export default NewEventWrapper;
