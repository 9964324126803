import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import LoadingOverlay from "./LoadingOverlay";

const AuthRouteWrapper = () => {
    const navigate = useNavigate();
    const { getIsAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!getIsAuthenticated()) {
            const currentPath =
                window.location.pathname + window.location.search;
            navigate(`/auth?redirect=${encodeURIComponent(currentPath)}`);
        }
    }, [getIsAuthenticated]);

    return <>{getIsAuthenticated() ? <Outlet /> : <LoadingOverlay />}</>;
};

export default AuthRouteWrapper;
