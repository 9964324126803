import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

export const getDefaultPath = (
    activeOrganizationId: string | null | undefined
) => {
    if (
        activeOrganizationId !== null &&
        activeOrganizationId !== undefined &&
        activeOrganizationId !== ""
    )
        return `/organizations/${activeOrganizationId}/events`;
    return "/profile";
};

export const useLocalSignIn = () => {
    const { signIn, signOut } = useContext(AuthContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return async (
        token: string,
        expiresIn: number,
        activeOrganizationId: string | null,
        nextRoute?: string
    ) => {
        signOut();
        queryClient.clear();
        signIn(token, expiresIn);
        navigate(
            nextRoute && nextRoute !== ""
                ? nextRoute
                : getDefaultPath(activeOrganizationId),
            {
                replace: true,
            }
        );
    };
};
