import { apiClient } from "@api/config";
import { Country, FeatureEnum } from "@api/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export interface GetOrganizationQueryOrganization {
    id: string;
    profilePicture: string;
    name: string;
    isOwnedByCurrentUser: boolean;
    permissions: {
        canManageEvents: boolean;
        canEditProfile: boolean;
        canValidateQrs: boolean;
        canSeeTotalQrsCount: boolean;
    };
    premiumCredits: number;
    featureToggles: {
        feature: FeatureEnum;
        enabled: boolean;
    }[];
    country: Country;
}

export const useGetOrganizationQuery = (
    orgId: string,
    options?: Omit<
        UseQueryOptions<AxiosResponse<GetOrganizationQueryOrganization>>,
        "queryKey"
    >
) =>
    useQuery(
        [useGetOrganizationQuery.name, orgId],
        () =>
            apiClient.get<GetOrganizationQueryOrganization>(
                `/organizations/${orgId}`
            ),
        options
    );
