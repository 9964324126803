import DefaultProfilePicture from "@assets/images/default_profile_picture.jpeg";
import {
    Box,
    HStack,
    Icon,
    Image,
    Link,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import { useCurrentOrganizationContext } from "@contexts/CurrentOrganizationContext";
import {
    replaceImageSrc,
    ResizedDimensions,
    withResizedDimensions,
} from "@utils/files";
import { truncateAndAddEllipsis } from "@utils/strings";
import { BiChevronRight } from "react-icons/bi";
import { Link as RouterLink } from "react-router-dom";

const height = { base: "fit-content", md: "4.5rem" };

interface Props {
    closeMobileSidebar?: () => void;
}

const ProfileSection = ({ closeMobileSidebar }: Props) => {
    const organization = useCurrentOrganizationContext().organization;
    return (
        <HStack
            px={5}
            w="full"
            bgColor={"dark_purple"}
            minH={height}
            maxH={height}
            py={{ base: 3, md: 0 }}
            spacing={3}
        >
            <Box
                boxSize="2.5rem"
                rounded="full"
                overflow="hidden"
                borderWidth={2}
                borderColor="primary"
            >
                <Image
                    src={
                        organization?.profilePicture !== ""
                            ? withResizedDimensions(
                                  organization.profilePicture,
                                  ResizedDimensions.XS
                              )
                            : DefaultProfilePicture
                    }
                    onError={(e) =>
                        replaceImageSrc(
                            e,
                            organization?.profilePicture ??
                                DefaultProfilePicture
                        )
                    }
                    objectFit="cover"
                    w="full"
                    h="full"
                />
            </Box>
            <VStack alignItems={"flex-start"} gap={0}>
                <Tooltip
                    label={
                        organization.name.length > 15
                            ? organization.name
                            : undefined
                    }
                >
                    <Text fontWeight={600}>
                        {truncateAndAddEllipsis(organization.name, 15)}
                    </Text>
                </Tooltip>
                <HStack spacing={0}>
                    <Link
                        as={RouterLink}
                        to={"/profile"}
                        color={"white"}
                        fontSize={"sm"}
                        onClick={closeMobileSidebar}
                    >
                        Tu perfil
                    </Link>
                    <Icon as={BiChevronRight} color={"white"} fontSize={"xl"} />
                </HStack>
            </VStack>
        </HStack>
    );
};

export default ProfileSection;
