import CreditsIcon from "@assets/icons/credits.svg?react";
import {
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Icon,
    Show,
    VStack,
} from "@chakra-ui/react";
import { useAuthContext } from "@contexts/AuthContext";
import { useCurrentOrganizationContext } from "@contexts/CurrentOrganizationContext";
import { useCallback, useMemo } from "react";
import { AiOutlineTeam } from "react-icons/ai";
import { BsCalendarEvent, BsCashCoin, BsWhatsapp } from "react-icons/bs";
import { GoBook } from "react-icons/go";
import { HiMiniQrCode } from "react-icons/hi2";
import { RxExit } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { tourKeys } from "../../../../../pages/organizations/{id}/events/components/NavigationIntroGuidedTour/NavigationIntroGuidedTour";
import ProfileSection from "./components/ProfileSection/ProfileSection";
import SideBarItem from "./components/SideBarItem/SideBarItem";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const SideBar = ({ isOpen, onClose }: Props) => {
    return (
        <>
            <Show above="lg">
                <VStack spacing={0}>
                    <SideBarContent />
                </VStack>
            </Show>
            <Show below="lg">
                <Drawer
                    isOpen={isOpen}
                    onClose={onClose}
                    placement="left"
                    size={"xs"}
                >
                    <DrawerOverlay />
                    <DrawerContent
                        maxW={"75vw"}
                        p={0}
                        background={"transparent"}
                    >
                        <DrawerCloseButton color={"white"} />

                        <DrawerBody bgColor={"transparent"} p={0}>
                            <VStack spacing={0} h="full">
                                <SideBarContent closeMobileSidebar={onClose} />
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Show>
        </>
    );
};

const SideBarContent = ({
    closeMobileSidebar,
}: {
    closeMobileSidebar?: () => void;
}) => {
    const navigate = useNavigate();
    const organization = useCurrentOrganizationContext().organization;
    const { user, signOut } = useAuthContext();

    const onSignOutClick = useCallback(() => {
        navigate("/");
        signOut();
    }, [navigate, signOut]);

    const items = useMemo(
        () => [
            [
                {
                    label: "Eventos",
                    icon: BsCalendarEvent,
                    to: `/organizations/${organization.id}/events`,
                    active: user?.activeOrganization !== null,
                },
                {
                    label: "Escanear QR",
                    icon: HiMiniQrCode,
                    to: `/organizations/${organization.id}/scan-qrs`,
                    active:
                        user?.activeOrganization !== null &&
                        organization?.permissions.canValidateQrs,
                },
            ],
            [
                {
                    label: "Créditos",
                    icon: CreditsIcon,
                    to: `/organizations/${organization.id}/premium-credits`,
                    active:
                        user?.activeOrganization !== null &&
                        organization?.isOwnedByCurrentUser,
                },
                {
                    label: "Cobros",
                    icon: BsCashCoin,
                    to: `/organizations/${organization.id}/external-links`,
                    active:
                        user?.activeOrganization !== null &&
                        organization?.isOwnedByCurrentUser,
                },
                {
                    label: "Equipo",
                    icon: AiOutlineTeam,
                    to: `/organizations/${organization.id}/team`,
                    active:
                        user?.activeOrganization !== null &&
                        organization?.isOwnedByCurrentUser,
                },
            ],
            [
                {
                    label: "Guía de uso",
                    icon: GoBook,
                    to: import.meta.env.VITE_DOCS_URL,
                    active: true,
                },
                {
                    label: "Contacto",
                    icon: BsWhatsapp,
                    to: `https://wa.me/${
                        import.meta.env.VITE_COMPANY_WHATSAPP
                    }`,
                    active: true,
                },
            ],
        ],
        [user?.activeOrganization, organization]
    );

    return (
        <>
            <ProfileSection closeMobileSidebar={closeMobileSidebar} />
            <VStack
                pos="relative"
                w="full"
                alignItems={"flex-start"}
                h={{ base: "fit-content", lg: "full" }}
                justifyContent={"flex-start"}
                gap={8}
                pt={10}
                _before={{
                    content: '""',
                    w: "full",
                    h: "full",
                    pos: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    backdropFilter: "blur(8px)",
                }}
                background={{
                    base: "rgba(22, 22, 24, .8)",
                    lg: "light_gray",
                }}
                flex={1}
                id={tourKeys.stepTwo}
            >
                {items
                    .filter((i) => i.some((j) => j.active))
                    .map((group, index) => (
                        <VStack
                            key={index}
                            spacing={5}
                            alignItems={"flex-start"}
                            w="full"
                        >
                            {group
                                .filter((i) => i.active)
                                .map((item, index) => (
                                    <SideBarItem
                                        key={index}
                                        to={item.to}
                                        label={item.label}
                                        icon={item.icon}
                                        closeMobileSidebar={closeMobileSidebar}
                                    />
                                ))}
                            <Divider borderColor={"gray.600"} pb={3} />
                        </VStack>
                    ))}
                <Button
                    w={"fit-content"}
                    whiteSpace={"nowrap"}
                    variant={"link"}
                    ps={"10 !important"}
                    pe={20}
                    textDecor={"none !important"}
                    leftIcon={
                        <Icon
                            fontSize={"xl"}
                            color={"white"}
                            userSelect="none"
                            as={RxExit}
                        />
                    }
                    iconSpacing={5}
                    color={"white"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    onClick={onSignOutClick}
                >
                    Cerrar sesión
                </Button>
            </VStack>
        </>
    );
};

SideBar.displayName = "SideBar";
export default SideBar;
