import Background from "@assets/images/auth_bg.webp";
import { Box, Heading, VStack } from "@chakra-ui/react";
import Logo from "@components/Navbar/Logo";
import { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

const AuthLayout = () => {
    const [title, setTitle] = useState("");

    return (
        <VStack
            w="full"
            h="100vh"
            justifyContent={["center", "flex-start"]}
            alignItems="center"
            p={5}
            pt={[0, "5rem"]}
            bgImage={Background}
            bgPos="center center"
            bgSize="cover"
            bgRepeat="no-repeat"
            spacing={6}
        >
            <Box h="2rem">
                <Logo />
            </Box>
            <VStack
                alignItems={"center"}
                w="full"
                maxW="40rem"
                minW="fit-content"
                px={[5, "7.5rem"]}
                pt={8}
                pb={20}
                bgColor="rgba(0, 0, 0, .75)"
                borderColor="borders"
                borderWidth={1}
                rounded="xl"
                spacing={10}
            >
                <Heading color="white" fontSize={"2xl"}>
                    {title}
                </Heading>
                <Outlet context={{ setTitle }} />
            </VStack>
        </VStack>
    );
};

export const useAuthLayoutContext = () => {
    return useOutletContext<{ setTitle: (title: string) => void }>();
};

export default AuthLayout;
