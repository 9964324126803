import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

export const getMenuTheme = () => {
    const { definePartsStyle, defineMultiStyleConfig } =
        createMultiStyleConfigHelpers(menuAnatomy.keys);

    const baseStyle = definePartsStyle({
        list: {
            bg: "dark_purple",
            border: "none",
            rounded: "xl",
            py: 4,
            px: 6,
        },
        item: {
            bg: "dark_purple",
            color: "white",
            _hover: {
                opacity: 0.8,
            },
            py: 3,
        },
    });

    return defineMultiStyleConfig({
        baseStyle,
    });
};
