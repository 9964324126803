import { Box, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import SideBar from "./components/SideBar/SideBar";
import TopBar from "./components/TopBar/TopBar";

const ApplicationLayout = () => {
    const { isOpen, onToggle } = useDisclosure();
    const [title, setTitle] = useState("");

    return (
        <Flex w="full" justifyContent="space-between" gap={0} h="100vh">
            <SideBar isOpen={isOpen} onClose={onToggle} />
            <VStack
                alignItems="flex-start"
                zIndex={1}
                justifyContent={"flex-start"}
                maxH="full"
                bgColor={"black"}
                flex={1}
                w={{ base: "full", md: "auto" }}
                overflow="auto !important"
                id="scroll-container"
            >
                <TopBar toggleSidebar={onToggle} title={title} />
                <Box
                    maxH="full"
                    px={{ base: 5, md: 20 }}
                    py={{ base: 5, md: 10 }}
                    w="full"
                    h="full"
                >
                    <Outlet context={{ setTitle }} />
                </Box>
            </VStack>
        </Flex>
    );
};

export const useApplicationLayoutContext = () => {
    return useOutletContext<{ setTitle: (title: string) => void }>();
};

export default ApplicationLayout;
